import React from 'react'

const DualTokenImage = ({ token1URI, token2URI, sm = false }) => {
  return (
    <div className='flex items-center -space-x-2'>
      <img className='relative z-10' width={sm ? 24 : 28} height={sm ? 24 : 28} alt='' src={token1URI} />
      <img className='relative z-[5]' width={sm ? 24 : 28} height={sm ? 24 : 28} alt='' src={token2URI} />
    </div>
  )
}

export default DualTokenImage

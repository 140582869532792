import React from 'react'

const InfoBanner = ({ logo, title, text, url, setBanner }) => {
  return (
    <div className='flex justify-between items-center w-full bg-themeOrangeLight/30 border border-themeOrange px-4 py-2 my-4 space-x-4 rounded-xl'>
      <div className='flex justify-start items-center w-[10%] py-2'>
        <img alt='' src={logo} width={32} />
      </div>
      <div className='flex flex-col justify-start items-center w-[80%]'>
        <p
          onClick={() => {
            url && window.open(url, '_blank')
          }}
          className={`text-white text-sm self-start font-semibold cursor-default ${url && 'hover:underline hover:cursor-pointer'}`}
        >
          {title}
        </p>
        {text && <p className='text-white text-sm self-start cursor-default'>{text}</p>}
      </div>
      <div className='flex justify-end items-center w-[10%]'>
        <img className='hover:cursor-pointer' alt='close-button' src='/images/common/close-button2.svg' width={16} onClick={() => setBanner(false)} />
      </div>
    </div>
  )
}

export default InfoBanner

import React, { useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import './style.scss'

const Menu = ({ item, idx }) => {
  const route = useLocation()
  const [more, setMore] = useState(false)

  return (
    <li key={`main-${idx}`} className={`links`}>
      {item.dropdown ? (
        <div className='relative'>
          <div
            onMouseEnter={() => {
              setMore(true)
            }}
            onMouseLeave={() => {
              setMore(false)
            }}
            className='flex items-center space-x-1 cursor-pointer relative z-10  font-light text-white'
          >
            <span>{item.name}</span>
            <img
              alt='dropdown'
              src='/images/header/dropdown-arrow.svg'
              className={`${!more ? 'rotate-180' : 'rotate-0'} transition-all duration-150 ease-in-out`}
            />
          </div>
          {more && (
            <div
              onMouseEnter={() => {
                setMore(true)
              }}
              onMouseLeave={() => {
                setMore(false)
              }}
              className='py-3 px-[22px] w-[174px] absolute top-10 bg-[#252525] text-left rounded-[20px] flex flex-col text-white text-lg leading-[33px] font-light'
            >
              {item.link.map((_item, j) => {
                return _item.external ? (
                  <div
                    className='doc-link'
                    onClick={() => {
                      window.open(_item.link, '_blank')
                    }}
                    key={`more-${j}`}
                  >
                    {_item.name}
                  </div>
                ) : (
                  <Link
                    key={`more-${j}`}
                    onClick={() => setMore(false)}
                    className={route.pathname.includes(_item.link) ? 'text-sky' : 'font-light '}
                    to={_item.link}
                  >
                    {_item.name}
                  </Link>
                )
              })}
            </div>
          )}
        </div>
      ) : (
        <Link className={route.pathname.includes(item.link) ? 'text-sky' : 'font-light' + ` ${item.class ?? item.class}`} to={item.link}>
          {item.name}
        </Link>
      )}
    </li>
  )
}

export default Menu

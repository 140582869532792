import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import Table from '../../components/pages/delegate/table'
import Timer from '../../components/common/Timer'
import { BIG_ZERO, ZERO_ADDRESS, formatAmount } from '../../utils/formatNumber'
import VeTHESelect from '../../components/common/VeTHESelect'
import { useEpochTimer, useVoteEmissions } from '../../hooks/useGeneral'
import { veTHEsContext } from '../../context/veTHEsConetext'
import usePrices from '../../hooks/usePrices'
import { FusionsContext } from '../../context/FusionsContext'
import _ from 'lodash'
import WarningTag from '../../components/common/WarningTag'

const sortOptions = [
  {
    label: 'APR',
    value: 'apr',
    isDesc: true,
  },
  {
    label: 'Rewards',
    value: 'rewards',
    isDesc: true,
  },
  {
    label: 'Active Votes',
    value: 'votes',
    isDesc: true,
  },
  {
    label: 'Total Votes',
    value: 'your',
    isDesc: true,
  },
]

const Delegate = () => {
  const [sort, setSort] = useState({})
  const [mobileFilter, setMobileFilter] = useState(false)
  const [veTHE, setVeTHE] = useState(null)
  const [percent, setPercent] = useState({})
  const fusions = useContext(FusionsContext)
  const { veTHEs, managerInfo } = useContext(veTHEsContext)
  const { voteEmssions } = useVoteEmissions()
  const { days, hours, mins, epoch } = useEpochTimer()
  const navigate = useNavigate()
  const prices = usePrices()
  let { veId } = useParams()

  const avgApr = useMemo(() => {
    if (fusions && fusions.length > 0 && prices) {
      const totalBribe = fusions.reduce((sum, cur) => {
        return sum.plus(cur.gauge.bribeUsd)
      }, new BigNumber(0))
      const totalWeight = fusions.reduce((sum, cur) => {
        return sum.plus(cur.gauge.weight)
      }, new BigNumber(0))
      const totalVoteUsd = totalWeight.times(prices['LYNX'])
      return totalVoteUsd.isZero() ? 0 : totalBribe.times(52).div(totalVoteUsd).times(100)
    }
    return new BigNumber(0)
  }, [fusions, prices])

  useEffect(() => {
    if (veTHEs && veTHEs.length > 0 && veId) {
      const item = veTHEs.find((ele) => ele.id === veId)
      if (!item) {
        navigate('/404')
        return
      }
      setVeTHE(item)
    }
  }, [veTHEs, veId])

  const totalInfo = useMemo(() => {
    return [
      {
        title: 'Your veLYNX Balance',
        balance: veTHE ? formatAmount(veTHE.voting_amount) : '-',
      },
      {
        title: 'Emissions / % of Vote',
        balance: '$' + formatAmount(voteEmssions),
      },
      {
        title: 'Average Voting APR',
        balance: formatAmount(avgApr) + '%',
      },
      {
        title: `Epoch ${epoch} Ends In`,
        balance: `${days}d ${hours}h ${mins}m`,
      },
    ]
  }, [veTHE, voteEmssions, avgApr, mins])

  const veTheActive = useMemo(() => {
    return veTHE?.thisWeekVotingPower.gt(0) && veTHE?.votedCurrentEpoch === false
  }, [veTHE])

  const gauges = useMemo(() => {
    return fusions.filter((pair) => pair.gauge.address !== ZERO_ADDRESS && pair.isValid)
  }, [fusions])

  const pools = useMemo(() => {
    return managerInfo.map((manager) => {
      const voted = []
      const allRewards = []
      let allBribeUsd = BIG_ZERO
      let voteApr = BIG_ZERO
      if (manager.votes?.length > 0) {
        gauges.forEach((ele) => {
          const found = manager.votes.find((vote) => ele.address.toLowerCase() === vote.address.toLowerCase())
          if (found) {
            const bribes = ele.gauge.bribes.bribe
            const fee = ele.gauge.bribes.fee
            const bribesUsd = ele.gauge?.bribeUsd || BIG_ZERO
            const totalWeight = ele.gauge.weight
            const voterShare = totalWeight.gt(0) ? found.weight.times(1e12).div(totalWeight) : BIG_ZERO
            const bribeRewards =
              bribes?.map((bribe) => {
                return {
                  ...bribe,
                  amount: voterShare.times(bribe.amount).div(1e12).toNumber(),
                }
              }) || []
            const feeRewards =
              fee?.map((fee) => {
                return {
                  ...fee,
                  amount: voterShare.times(fee.amount).div(1e12).toNumber(),
                }
              }) || []
            const rewards = [...bribeRewards, ...feeRewards]
            const votedBribesUsd = bribesUsd.times(voterShare).div(1e12)
            allBribeUsd = allBribeUsd.plus(votedBribesUsd)
            voteApr = ele.gauge.voteApr.times(found.weightPercent).div(100).plus(voteApr)
            allRewards.push(...rewards)
            voted.push({
              ...ele,
              bribes,
              rewards,
              bribesUsd: votedBribesUsd.toNumber(),
              voterShare: voterShare.div(1e10).toNumber(),
              weight: found.weight,
              weightPercent: found.weightPercent,
            })
          }
        })
      }
      const aggregatedRewards = _(allRewards)
        .groupBy('address')
        .map((ele) => ({
          ...ele[0],
          amount: _.sumBy(ele, 'amount'),
        }))
        .value()

      return {
        ...manager,
        voted,
        allBribeUsd,
        apr: voteApr,
        aggregatedRewards,
        allRewards,
      }
    })
  }, [fusions, managerInfo])

  useEffect(() => {
    if (veTHE) {
      setVeTHE(veTHEs.find((item) => item.id === veTHE.id))
    }
  }, [veTHEs, veTHE])

  const totalPercent = useMemo(() => {
    return Object.values(percent).reduce((sum, current) => {
      return sum + (!current || current === '' ? 0 : Number(current))
    }, 0)
  }, [percent])

  return (
    <>
      <div className='max-w-[1200px] px-5 sm:px-16 md:px-28 mdLg:px-40 lg:px-5 xl:px-0 pt-[160px] mx-auto'>
        <div className='lg:flex items-start justify-between lg:space-x-2 xl:space-x-[20px]'>
          <div className='w-full lg:w-[30%] xl:w-1/3'>
            <div className='max-w-[450px]'>
              <h1 className='text-[34px] md:text-3xl font-semibold text-white  f-f-fg'>Delegate to Manager</h1>
              <p className='text-[#b8b6cb] text-base md:text-lg leading-[22px] md:leading-6 mt-1'>
                Delegate your veLYNX voting power, eliminating the requirement for weekly voting. Enjoy a seamless 'set-and-forget' mechanic. {''}
              </p>
            </div>
          </div>
          <Timer arr={totalInfo} className={`w-full lg:w-[70%] xl:w-2/3 mt-4 lg:mt-0`} />
        </div>
        <div className='flex flex-col lg:flex-row items-center justify-between w-full mt-4 lg:mt-[23px] lg:space-x-7 xl:space-x-[60px] relative'>
          <div className='w-full lg:w-[55%] xl:w-1/2 lg:flex-row flex flex-col lg:items-center lg:space-x-5'>
            <div className='flex items-center w-full mt-3.5 lg:mt-0'>
              <VeTHESelect className={'lg:w-[300px] w-full'} isSelected={veTHE} setIsSelected={setVeTHE} />
              {/* filter button for mobile */}
              <button
                onClick={() => {
                  setMobileFilter(!mobileFilter)
                }}
                className='w-12 flex-shrink-0 h-[42px] lg:hidden ml-3'
              >
                <img alt='' className='w-12 h-[42px]' src='/images/liquidity/filter.svg' />
              </button>
            </div>
          </div>
          <WarningTag text={'All numbers are merely projections and can change at any time relative to fees, bribes and voter behaviour'} />
          {veTheActive && <WarningTag text={'This veLYNX position has no active delegations on the current epoch. You still need to manually vote'} />}
        </div>

        <Table
          pools={pools}
          sort={sort}
          setSort={setSort}
          sortOptions={sortOptions}
          veTHE={veTHE}
          percent={percent}
          setPercent={setPercent}
          totalPercent={totalPercent}
        />
      </div>
    </>
  )
}

export default Delegate

import React from 'react'

const LinearGhostButton = ({ title, onClickHanlder, className, smallCase, disabled }) => {
  return (
    <button
      className={`py-2 text-white f-f-fg leading-5 w-full  ${className} ${
        smallCase ? '' : 'uppercase'
      } bg-themeOrange !font-medium tracking-[1.44px] text-sm rounded-lg flex items-center justify-center flex-col
      ${disabled && '!bg-gray-500/80 !text-gray-300 cursor-not-allowed'} `}
      onClick={() => {
        onClickHanlder()
      }}
      disabled={disabled}
    >
      {title}
    </button>
  )
}

export default LinearGhostButton

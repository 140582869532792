import { ZERO_VALUE } from '../../utils/formatNumber'

export const managers = [
  {
    title: 'lynex',
    logo: 'lynex.svg',
    address: '0x410303943dc392631FB202F429C363DeA1FEb2E2',
    apr: 62.43,
    votingPower: 29.78,
    rewards: ZERO_VALUE,
    analyticsId: 'p_ig7iodbzid',
  },
  {
    title: 'ichi',
    logo: 'ichi.svg',
    address: '0x4a1E305220C510b4163c11602c68804fCE99B4e5',
    apr: 62.43,
    votingPower: 29.78,
    rewards: ZERO_VALUE,
    analyticsId: 'p_d5y3vb8zid',
  },
  {
    title: 'gamma',
    logo: 'gamma.svg',
    address: '0xC8332131Adf037bf933FF4d885A41BBeA987bBFf',
    apr: 62.43,
    votingPower: 29.78,
    rewards: ZERO_VALUE,
    analyticsId: 'p_5z35m27zid',
  },
  {
    title: 'steer',
    logo: 'steer.svg',
    address: '0x5101a37f2e2a0BD4D3d53D9A3BCc8eCd8E3d5848',
    apr: 62.43,
    votingPower: 29.78,
    rewards: ZERO_VALUE,
    analyticsId: 'p_0dnp3d8zid',
  },
  {
    title: 'defiedge',
    logo: 'defiedge.svg',
    address: '0x27Ea021657F64f7B4beef87C7621BEAD3Cc2FfB8',
    apr: 62.43,
    votingPower: 29.78,
    rewards: ZERO_VALUE,
    analyticsId: 'p_6xcd0f8zid',
  },
]

export const delegatees = [
  ...managers,
  {
    title: 'mendi',
    logo: 'mendi.png',
    address: '0xe3CDa0A0896b70F0eBC6A1848096529AA7AEe9eE',
    apr: 62.43,
    votingPower: 29.78,
    rewards: ZERO_VALUE,
  },
]

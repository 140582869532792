import React, { useMemo, useState } from 'react'
import Sticky from 'react-stickynode'
import ReactTooltip from 'react-tooltip'
import { TransactButton } from '../../common/Buttons/transactButton'
import useWalletModal from '../../../hooks/useWalletModal'
import { formatAmount } from '../../../utils/formatNumber'
// import DepositModal from '../liquidity/depositModal'
import { useHarvest } from '../../../hooks/useGauge'
import StyledButton from '../../common/Buttons/styledButton'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
import MobilePoolInfo from './mobilePoolInfo'
import NoFound from '../../common/NoFound'
import { BadgeContainer } from '../liquidity/badgeContainer'
import { blueChipAddresses } from '../../../config/tokens/tokenlists'
import { Strategies } from '../../../config/constants/strategies'
import '../liquidity/style.scss'
import DepositIchiModal from '../liquidity/depositIchiModal'

const Item = ({ usd, content, type, extraRewards }) => {
  return (
    <div className='flex flex-col items-start justify-end text-sm'>
      {type === 'earning' && !extraRewards && <p className='invisible'>-</p> /* Align earnings with other items when no extra rewards */}
      <div className='text-dimGray'>{content}</div>
      <p className='text-white text-base'>{'$' + formatAmount(usd ?? 0, true)}</p>
    </div>
  )
}

const TableRow = ({ item, idx }) => {
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const { onHarvest, pending } = useHarvest()
  const [manage, setManage] = useState(false)
  const finished = !item.gauge.isAlive
  const depositToken = item && item.allowedToken0 ? item.token0 : item.token1
  const quoteToken = item && item.allowedToken0 ? item.token1 : item.token0
  const lpSymbol = item && depositToken.symbol + '/' + quoteToken.symbol
  const poolPercentage = useMemo(() => {
    return depositToken.address === item.token0.address
      ? item.token0.reserve.times(item.token0.price).times(100).div(item.tvl)
      : item.token1.reserve.times(item.token1.price).times(100).div(item.tvl)
  }, [item.tvl])
  const stratPercentage = useMemo(() => {
    return Strategies[process.env.REACT_APP_CHAIN_ID].find((strat) => strat.address.toLowerCase() === item.address.toLowerCase()).percentage
  }, [item])

  const isPromoted = false // item && item.promoted Temporal
  const isBlueChip = blueChipAddresses?.includes(depositToken?.address.toLowerCase())

  return (
    <div key={idx} className={`${isPromoted ? 'table-row-wrapper-promoted' : 'table-row-wrapper pt-0'}  flex-col`}>
      <div className='absolute top-0 right-0 h-[28px]'>
        <img src='/images/liquidity/powered-by-ichi.svg' />
      </div>
      {isPromoted && <div className='ribbon'>Featured</div>}
      <div className={`flex flex-col lg:flex-row w-full items-center rounded-[6px] pt-[28px] ${isPromoted && 'bg-[#262626] py-5 px-6'}`}>
        <div className='w-full lg:w-[48%] lg:pr-8'>
          <div className='flex w-full justify-between space-x-2'>
            <div className='flex items-center space-x-2'>
              <div className='flex items-center justify-start'>
                <img className='relative z-10' alt='' src={depositToken?.logoURI} width={32} height={32} />
              </div>
              <div>
                <p className='text-xl font-semibold'>{lpSymbol}</p>
                <p className='tracking-wide text-xs text-dimGray'>Single-Sided Deposit</p>
              </div>
              <div className='hidden lg:flex space-x-2'>
                <div className='tag'>Thick</div>
                {isBlueChip ? <div className='tag'>Blue Chip</div> : <div className='tag'>LST</div>}
              </div>
            </div>
            <div className='flex flex-col items-end justify-end text-md -space-y-[2px] md:space-y-0'>
              <div data-tip data-for={`${item}-${idx}`} className='flex space-x-1 items-center flex-wrap'>
                <span className='text-sm text-dimGray'>Up to:&nbsp;</span>
                {finished ? '0%' : item.gauge.apr > 0 ? `${formatAmount(item.gauge.apr)}%` : `( ~ )`}
              </div>
              <BadgeContainer item={item} />
            </div>
            <ReactTooltip
              className='max-w-[270px] !text-[#E6E6E6] !text-base !py-[9px] !px-6 !opacity-100 !bg-[#252525] !border !border-[#ffffff33] !rounded-xl after:!bg-[#252525] after:!border-[#ffffff33] after:!border-[#ffffff33]'
              id={`${item}-${idx}`}
              place='right'
              effect='solid'
              delayShow={200}
            >
              <div className='tooltip-content'>
                <div className='tooltip-row'>
                  <span>oLYNX → veLYNX</span>
                  <span>{formatAmount(item.gauge.veTokenApr, true)}%</span>
                </div>
                <div className='tooltip-row'>
                  <span>oLYNX → LYNX</span>
                  <span>{formatAmount(item.gauge.minApr, true)}%</span>
                </div>
                {item.extraRewards?.length > 0 &&
                  item.extraRewards
                    .filter((reward) => reward.apr > 0)
                    .map((reward) => {
                      return (
                        <div className='tooltip-row' key={reward.symbol}>
                          <span>{reward.symbol}</span>
                          <span>{formatAmount(reward.apr, true)}%</span>
                        </div>
                      )
                    })}
              </div>
            </ReactTooltip>
          </div>
          <div className='flex space-x-2 my-2 lg:hidden'>
            <div className='tag'>Thick</div>
            {isBlueChip ? <div className='tag'>Blue Chip</div> : <div className='tag'>LST</div>}
          </div>
          <div className='w-full'>
            <p className='text-[12px] mt-3'>
              This strategy maintains a Target Exposure of {stratPercentage}%+ towards {depositToken.symbol} only, by depositing {depositToken.symbol} lopsided
              into {lpSymbol} concentrated liquidity pool. Aims to give back {stratPercentage}%+ {depositToken.symbol} and ~{100 - stratPercentage}%{' '}
              {quoteToken.symbol} during redemptions. Low to Medium exposure to Impermanent Loss.
            </p>
          </div>
          <div className='w-full relative h-10 items-center flex justify-center'>
            <div className='pool-bar' />
            <div className='pool-completion-bar' style={{ width: `${formatAmount(poolPercentage)}%` }} />
            <div className='percentage'>
              {formatAmount(poolPercentage)}% {depositToken?.symbol}
            </div>
          </div>
        </div>
        <div className='hidden lg:flex flex-col items-start mt-3 lg:mt-0 w-1/2 lg:w-[13%] justify-center'>
          <Item
            type={'tvl'}
            usd={item.gauge.tvl}
            content={
              <>
                {formatAmount(item.token0.reserve)} {item.token0.symbol}
                <br />
                {formatAmount(item.token1.reserve)} {item.token1.symbol}
              </>
            }
          />
        </div>
        <div className='hidden lg:flex flex-col items-start mt-3 lg:mt-0 w-1/2 lg:w-[13%] justify-center'>
          <Item
            type={'stake'}
            usd={item.account.totalUsd}
            content={
              <>
                {formatAmount(item.account.total0)} {item.token0.symbol}
                <br />
                {formatAmount(item.account.total1)} {item.token1.symbol}
              </>
            }
          />
        </div>
        <div className='hidden lg:flex flex-col items-start w-1/2 mt-3 lg:mt-0 lg:w-[13%] justify-center'>
          <Item
            type={'earning'}
            usd={item.account.earnedUsd}
            extraRewards={item.extraRewards.length > 0}
            content={
              <>
                {item.extraRewards?.length > 0 &&
                  item.extraRewards
                    .filter((reward) => !reward.isFinished || (reward.isFinished && !reward.amount.isZero()))
                    .map((reward) => {
                      return (
                        <div key={reward.symbol}>
                          {formatAmount(reward.amount)} {reward.symbol}
                          <br />
                        </div>
                      )
                    })}
                {formatAmount(item.account.gaugeEarned)} oLYNX
              </>
            }
          />
        </div>
        <div className='block lg:hidden w-full'>
          <MobilePoolInfo item={item} />
        </div>
        <div className='block w-full lg:w-[13%] mt-3.5 lg:mt-0'>
          {account ? (
            <div className='space-x-4 lg:space-y-4 w-full flex lg:flex-col items-center lg:items-end justify-around lg:justify-end'>
              <TransactButton
                disabled={pending || item.account?.gaugeEarned.isZero()}
                className='h-8 lg:max-w-[96px] text-xs tracking-normal w-full rounded-md'
                bg='rgba(223, 131, 47, 0.2)'
                onClickHandler={() => {
                  onHarvest(
                    item,
                    item?.extraRewards?.map((reward) => reward.asset.address),
                  )
                }}
                content={'Claim'}
              />
              <TransactButton
                onClickHandler={() => {
                  setManage(true)
                }}
                bg='rgba(223, 131, 47, 0.2)'
                className='h-8 lg:max-w-[96px] text-xs tracking-normal w-full rounded-md'
                content={'Manage'}
              />
            </div>
          ) : (
            <div className='flex items-center justify-center lg:justify-end rounded-md'>
              <StyledButton onClickHandler={() => openWalletModal()} content={'CONNECT WALLET'} className='py-[10px] px-3 text-xs' />
            </div>
          )}
        </div>
      </div>
      {manage && <DepositIchiModal isOpen={manage} setIsOpen={setManage} pair={item} depositToken={depositToken} />}
    </div>
  )
}

const FarmTable = ({ pairsData, sort, setSort, sortOptions }) => {
  return (
    <>
      {/* for desktop */}
      <div className='w-full mt-[42px]'>
        <div className='w-full'>
          {pairsData.length > 0 ? (
            <>
              <Sticky
                enabled={false}
                innerActiveClass={'bg-themeOrangeLight rounded-b-lg'}
                top={95}
                bottomBoundary={1200}
                activeClass={''}
                innerClass={'sticky-table-header'}
                className={`z-[100]`}
              >
                {sortOptions.map((option, index) => (
                  <div className={`${index === 0 ? 'w-[41%]' : index === 1 ? 'w-[8%]' : 'w-[13%]'} text-sm text-white f-f-fg`} key={`header-${index}`}>
                    <div
                      onClick={() => {
                        setSort({
                          ...option,
                          isDesc: sort.value === option.value ? !sort.isDesc : true,
                        })
                      }}
                      className='flex items-center cursor-pointer space-x-1 -ml-1 relative'
                    >
                      <p className='flex items-center'>{option.label}</p>
                      {sort.value === option.value && (
                        <button className={`${sort.isDesc ? '' : 'rotate-180'} transform`}>
                          <img alt='' src='/images/liquidity/arrow-bottom.svg' />
                        </button>
                      )}
                    </div>
                  </div>
                ))}
                <div className='w-[13%] font-medium text-[14px] xl:text-[16px] text-white f-f-fg'></div>
              </Sticky>
              <div className='flex flex-col gap-3'>
                {pairsData.map((item, idx) => {
                  return <TableRow isLast={idx === pairsData.length - 1} idx={`${idx}-${item.address}`} item={item} key={`row-${idx}-${item.address}`} />
                })}
              </div>
            </>
          ) : (
            <NoFound title='No pools found' />
          )}
        </div>
      </div>
    </>
  )
}

export default FarmTable

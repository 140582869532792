import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { completeTransaction, openTransaction } from '../state/transactions/actions'
import { TransactionType } from '../config/constants'
import { v4 as uuidv4 } from 'uuid'
import { sendContract } from '../utils/api'
import useWeb3, { useWeb3Wagmi } from './useWeb3'
import { useV3Voter } from './useContract'
import { cloneDeep } from 'lodash'
import { customNotify } from '../utils/notify'

const useVote = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const voterContract = useV3Voter()

  const handleVote = useCallback(
    async (votes) => {
      const totalVotes = Object.values(votes).reduce((sum, current) => sum + (Number(current) || 0), 0)
      if (totalVotes !== 100) {
        customNotify('Total votes should be 100%', 'error')
        return
      }
      const key = uuidv4()
      const voteuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Cast votes`,
          transactions: {
            [voteuuid]: {
              desc: `Cast votes`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      let votesToProcess = cloneDeep(votes)

      for (let i = 0; i < Object.keys(votesToProcess).length; i++) {
        const key = Object.keys(votesToProcess)[i]
        if (isNaN(Number(votesToProcess[key])) || Number(votesToProcess[key]) === 0) {
          delete votes[key]
          delete votesToProcess[key]
        } else {
          votesToProcess[key] = parseInt(votesToProcess[key] * 100)
        }
      }
      const tokens = Object.keys(votesToProcess)
      const voteCounts = Object.values(votesToProcess)

      setPending(true)
      const params = [tokens, voteCounts]
      try {
        await sendContract(dispatch, key, voteuuid, voterContract, 'vote', params, account)
      } catch (err) {
        console.log('vote error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'You have voted!',
        }),
      )
      setPending(false)
    },
    [account, web3, voterContract],
  )

  return { onVote: handleVote, pending }
}

export { useVote }

import React, { useState, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import TransparentButton from '../../common/Buttons/transparentButton'
import StyledButton from '../../common/Buttons/styledButton'
import CommonHollowModal from '../../common/CommonHollowModal'
import Tab from '../../common/Tab'
import BalanceInput from '../../common/Input/BalanceInput'
import { useStake, useUnstake } from '../../../hooks/useGauge'
import { isInvalidAmount } from '../../../utils/formatNumber'
import { customNotify } from '../../../utils/notify'

const DepositModal = ({ isOpen, setIsOpen, pair, stake = true }) => {
  const [isStake, setIsStake] = useState(stake)
  const [stakeAmount, setStakeAmount] = useState('')
  const { onStake, pending: stakePending } = useStake()
  const [withdrawAmount, setWithdrawAmount] = useState('')
  const { onUnstake, pending: unstakePending } = useUnstake()
  const navigate = useNavigate()

  const stakeBtnMsg = useMemo(() => {
    if (pair) {
      if (isInvalidAmount(stakeAmount)) {
        return {
          isError: true,
          label: 'INVALID AMOUNT',
        }
      }

      if (pair.account.lpBalance.lt(stakeAmount)) {
        return {
          isError: true,
          label: 'INSUFFICIENT LP BALANCE',
        }
      }
    }
    return {
      isError: false,
      label: 'STAKE LP',
    }
  }, [pair, stakeAmount])

  const unstakeErrorMsg = useMemo(() => {
    if (pair) {
      if (isInvalidAmount(withdrawAmount)) {
        return 'Invalid Amount'
      }
      if (pair.account.gaugeBalance.lt(withdrawAmount)) {
        return 'Insufficient ' + pair.symbol + ' Balance'
      }
    }
    return null
  }, [withdrawAmount, pair])

  const unstakeBtnMsg = useMemo(() => {
    if (pair) {
      if (isInvalidAmount(withdrawAmount)) {
        return {
          isError: true,
          label: 'INVALID AMOUNT',
        }
      }

      if (pair.account.gaugeBalance.lt(withdrawAmount)) {
        return {
          isError: true,
          label: 'INSUFFICIENT LP BALANCE',
        }
      }
    }
    return {
      isError: false,
      label: 'UNSTAKE LP',
    }
  }, [pair, withdrawAmount])

  const onRedirect = useCallback(() => {
    if (pair.isGamma) {
      navigate(`/liquidity/fusion?currency0=${pair.token0.address}&currency1=${pair.token1.address}&strategy=${pair.title}`)
    } else {
      navigate(`/liquidity/manage/${pair.address.toLowerCase()}`)
    }
  }, [pair])

  return (
    <CommonHollowModal popup={isOpen} width={588} setPopup={setIsOpen} title={`Manage liquidity`}>
      <div className='w-full mt-[29px] flex items-center justify-center flex-col'>
        <Tab leftTitle={'STAKE'} rightTitle={'UNSTAKE'} isLeft={isStake} setIsLeft={setIsStake} />
        {isStake ? (
          <div className='w-full flex items-center justify-center flex-col mt-5'>
            <BalanceInput
              title=''
              inputAmount={stakeAmount}
              setInputAmount={setStakeAmount}
              symbol={pair.symbol}
              balance={pair.account.lpBalance}
              logoURIs={[pair.token0.logoURI, pair.token1.logoURI]}
            />
            <div className='flex items-center space-x-3.5 mt-5 group cursor-pointer' href='#' onClick={() => onRedirect()}>
              <p className='text-sm md:text-xl text-white font-medium'>Get {pair.symbol} LP</p>
              <img className='group-hover:w-[40px] w-[30px] duration-300 ease-in-out stroke-white' alt='' src='/images/common/spear.svg' />
            </div>
            <div className='flex items-center mt-[26px] w-full space-x-2'>
              <StyledButton
                content={stakePending ? 'PENDING...' : stakeBtnMsg.label}
                disabled={stakePending || stakeBtnMsg.isError}
                onClickHandler={() => {
                  if (stakePending) {
                    return
                  }
                  onStake(pair, stakeAmount)
                }}
                className='w-1/2 py-[13px] md:py-[14px] px-[8px] md:px-[20px] text-[13px] md:text-[17px] '
              />
              <TransparentButton
                onClickHandler={() => setIsOpen(false)}
                content={'CANCEL'}
                className='
                py-[13px] md:py-[13px] px-[20px] text-white flex items-center justify-center text-[13px] md:text-[17px] w-1/2 tracking-[1.12px] md:tracking-[1.44px]'
              />
            </div>
          </div>
        ) : (
          <div className='w-full flex items-center justify-center flex-col mt-5'>
            <BalanceInput
              title=''
              inputAmount={withdrawAmount}
              setInputAmount={setWithdrawAmount}
              symbol={pair.symbol}
              balance={pair.account.withdrawBalance}
              logoURIs={[pair.token0.logoURI, pair.token1.logoURI]}
            />
            <div className='flex items-center mt-[26px] w-full space-x-5'>
              <StyledButton
                content={unstakePending ? 'PENDING...' : unstakeBtnMsg.label}
                disabled={unstakePending || unstakeBtnMsg.isError}
                onClickHandler={() => {
                  if (unstakeErrorMsg) {
                    customNotify(unstakeErrorMsg, 'warn')
                    return
                  }
                  onUnstake(pair, withdrawAmount)
                }}
                className='w-1/2 py-[13px] md:py-[14px] px-[8px] md:px-[20px] text-[13px] md:text-[17px] '
              />
              <TransparentButton
                onClickHandler={() => setIsOpen(false)}
                content={'CANCEL'}
                className='
                py-[13px] md:py-[13px] px-[20px] text-white flex items-center justify-center text-[13px] md:text-[17px] w-1/2 tracking-[1.12px] md:tracking-[1.44px]'
              />
            </div>
          </div>
        )}
      </div>
    </CommonHollowModal>
  )
}

export default DepositModal

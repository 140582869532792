import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  background-image: ${({ disabled, bg }) => (disabled ? 'unset' : bg ?? 'linear-gradient(to right, #DF832F, #E3974F, #E6A160, #DF832F)')};
  background-size: 300% 100%;
`
const StyledButton = ({ content, className, onClickHandler = null, bg, disabled, pending = false }) => {
  return (
    <Button
      bg={bg}
      disabled={disabled}
      role={'button'}
      aria-disabled={disabled}
      onClick={(e) => {
        if (!disabled && !pending && onClickHandler) {
          onClickHandler(e.target.value)
        }
      }}
      className={`f-f-fg flex items-center justify-center text-white !font-medium tracking-[1.44px] rounded-lg ${className ? ' ' + className : ''}${
        disabled ? ' !bg-gray-500/80 !text-gray-300 cursor-not-allowed' : ''
      } `}
    >
      {pending ? 'PENDING...' : content}
    </Button>
  )
}

export default StyledButton

import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'
import { BaseAssetsConetext } from '../../../context/BaseAssetsConetext'
import TokenInput from '../../common/Input/TokenInput'
import { formatAmount, isInvalidAmount, ZERO_ADDRESS } from '../../../utils/formatNumber'
import { customNotify } from '../../../utils/notify'
import { useAddLiquidity } from '../../../hooks/useLiquidity'
import BigNumber from 'bignumber.js'
import LiquidityDetails from './liquidityDetails'
import { getWBNBAddress } from '../../../utils/addressHelpers'
import { useImportTokens } from '../../../state/application/hooks'
import { TransactButton } from '../../common/Buttons/transactButton'
import WarningTag from '../../common/WarningTag'
import { useStableAssets } from '../../../hooks/v3/useStableAssets'

const getAddress = (asset) => {
  if (asset.address === 'ETH') {
    return getWBNBAddress().toLowerCase()
  }
  return asset.address.toLowerCase()
}

let previousPair

const AddLiquidity = ({ slippage, deadline, pairAddress, pairs }) => {
  const [firstAmount, setFirstAmount] = useState('')
  const [firstAsset, setFirstAsset] = useState()
  const [secondAmount, setSecondAmount] = useState('')
  const [secondAsset, setSecondAsset] = useState()
  const [stable, setStable] = useState(false)
  const [init, setInit] = useState(false)
  const { onAdd, onAddAndStake } = useAddLiquidity()
  const baseAssets = useContext(BaseAssetsConetext)
  const { importedTokens } = useImportTokens()
  const stableAssets = useStableAssets()

  const isFirstTokenImported = firstAsset ? importedTokens.some((asset) => asset.address.toLowerCase() === firstAsset.address.toLowerCase()) : false
  const isFirstTokenStable = firstAsset ? stableAssets.some((asset) => asset.address.toLowerCase() === firstAsset.address.toLowerCase()) : false
  const isSecondTokenImported = secondAsset ? importedTokens.some((asset) => asset.address.toLowerCase() === secondAsset.address.toLowerCase()) : false
  const isSecondTokenStable = secondAsset ? stableAssets.some((asset) => asset.address.toLowerCase() === secondAsset.address.toLowerCase()) : false
  const tradingWithImported = isFirstTokenImported || isSecondTokenImported
  const tradingWithStables = isFirstTokenStable && isSecondTokenStable

  useEffect(() => {
    if (!pairAddress) {
      if (!firstAsset) {
        setFirstAsset(baseAssets.find((asset) => asset.symbol === 'ETH'))
      }
      if (!secondAsset) {
        setSecondAsset(baseAssets.find((asset) => asset.symbol === 'USDC'))
      }
    } else if (pairs && pairs.length > 0 && !init) {
      const item = pairs.find((ele) => ele.address.toLowerCase() === pairAddress)
      setFirstAsset(
        baseAssets.find((asset) =>
          item.token0.address.toLowerCase() === getWBNBAddress().toLowerCase()
            ? asset.symbol === 'ETH'
            : asset.address.toLowerCase() === item.token0.address.toLowerCase(),
        ),
      )
      setSecondAsset(
        baseAssets.find((asset) =>
          item.token1.address.toLowerCase() === getWBNBAddress().toLowerCase()
            ? asset.symbol === 'ETH'
            : asset.address.toLowerCase() === item.token1.address.toLowerCase(),
        ),
      )
      setStable(item.stable)
      setInit(true)
    }
  }, [baseAssets, pairAddress, pairs])

  useEffect(() => {
    if (firstAsset) {
      setFirstAsset(baseAssets.find((asset) => asset.symbol === firstAsset.symbol))
    }
    if (secondAsset) {
      setSecondAsset(baseAssets.find((asset) => asset.symbol === secondAsset.symbol))
    }
  }, [baseAssets, firstAsset, secondAsset, importedTokens])

  const pair = useMemo(() => {
    if (pairs && pairs.length > 0 && firstAsset && secondAsset) {
      return pairs.find(
        (item) =>
          [getAddress(firstAsset), getAddress(secondAsset)].includes(getAddress(item.token0)) &&
          [getAddress(firstAsset), getAddress(secondAsset)].includes(getAddress(item.token1)) &&
          item.stable === stable &&
          !item.isGamma,
      )
    } else {
      return null
    }
  }, [firstAsset, secondAsset, pairs, stable])

  const tvl = useMemo(() => {
    return pair?.lpPrice.times(pair.totalSupply)
  }, [pair])
  const lowTvl = tvl?.lt(10000) ?? false

  const isReverse = useMemo(() => {
    if (pair && firstAsset) {
      return getAddress(pair.token1) === getAddress(firstAsset)
    }
    return false
  }, [pair, firstAsset])

  const wrongAssets = useMemo(() => {
    if (pair) {
      return (!pair.stable && tradingWithStables) || (pair.stable && !tradingWithStables)
    } else return (!stable && tradingWithStables) || (stable && !tradingWithStables)
  }, [pair, firstAsset, secondAsset, stable])

  const countDecimals = function (value) {
    if (Math.floor(value) === value) return 0
    return value.toString().split('.')[1]?.length || 0
  }

  const computeAmount = function (val, asset) {
    const inputDecimals = countDecimals(val)
    const maxDecimals = asset?.decimals || 18
    return inputDecimals > maxDecimals ? new BigNumber(val).dp(maxDecimals) : val
  }

  const onFirstChange = useCallback(
    (val) => {
      setFirstAmount(computeAmount(val, firstAsset))
      if (pair && pair.totalSupply) {
        const firstReserve = isReverse ? pair.token1.reserve : pair.token0.reserve
        const secondReserve = isReverse ? pair.token0.reserve : pair.token1.reserve
        setSecondAmount(val ? secondReserve.times(val).div(firstReserve).dp(secondAsset.decimals).toString(10) : '')
      }
    },
    [isReverse, pair, computeAmount, setFirstAmount, setSecondAmount],
  )

  const onSecondChange = useCallback(
    (val) => {
      setSecondAmount(computeAmount(val, secondAsset))
      if (pair && pair.totalSupply) {
        const firstReserve = isReverse ? pair.token1.reserve : pair.token0.reserve
        const secondReserve = isReverse ? pair.token0.reserve : pair.token1.reserve
        setFirstAmount(val ? firstReserve.times(val).div(secondReserve).dp(firstAsset.decimals).toString(10) : '')
      }
    },
    [isReverse, pair, computeAmount, setFirstAmount, setSecondAmount],
  )

  const errorMsg = useMemo(() => {
    if (!firstAsset || !secondAsset) {
      return `Invalid Asset`
    }
    if (isInvalidAmount(firstAmount) || isInvalidAmount(secondAmount)) {
      return `Invalid Amount`
    }
    if (firstAsset.balance.lt(firstAmount)) {
      return 'Insufficient ' + firstAsset.symbol + ' Balance'
    }
    if (secondAsset.balance.lt(secondAmount)) {
      return 'Insufficient ' + secondAsset.symbol + ' Balance'
    }
    return null
  }, [firstAmount, secondAmount, firstAsset, secondAsset])

  const onAddAndStakeLiquidity = useCallback(() => {
    if (errorMsg) {
      customNotify(errorMsg, 'warn')
      return
    }
    onAddAndStake(pair, firstAsset, secondAsset, firstAmount, secondAmount, stable, slippage, deadline)
  }, [pair, firstAsset, secondAsset, firstAmount, secondAmount, stable, slippage, deadline])

  const onAddLqiduity = useCallback(() => {
    if (errorMsg) {
      customNotify(errorMsg, 'warn')
      return
    }
    onAdd(firstAsset, secondAsset, firstAmount, secondAmount, stable, slippage, deadline)
  }, [firstAsset, secondAsset, firstAmount, secondAmount, stable, slippage, deadline])

  useEffect(() => {
    if (pair) {
      if (previousPair !== pair.address) {
        previousPair = pair.address
        const firstReserve = isReverse ? pair.token1.reserve : pair.token0.reserve
        const secondReserve = isReverse ? pair.token0.reserve : pair.token1.reserve
        if (firstAmount && secondAmount) {
          setSecondAmount(secondReserve.times(firstAmount).div(firstReserve).dp(secondAsset.decimals).toString(10))
        }

        if (!firstAmount && secondAmount) {
          setFirstAmount(firstReserve.times(secondAmount).div(secondReserve).dp(firstAsset.decimals).toString(10))
        }

        if (firstAmount && !secondAmount) {
          setSecondAmount(secondReserve.times(firstAmount).div(firstReserve).dp(secondAsset.decimals).toString(10))
        }
      } else {
        previousPair = pair.address
      }
    } else {
      previousPair = undefined
    }
  }, [pair, isReverse])

  return (
    <>
      <div className='mt-5 mb-5 border border-[#ffffff33] rounded-[12px] text-dimGray max-w-[auto] w-full f-f-fg text-[13px] md:text-sm tracking-[0.56px] h-[34px] md:h-[38px] flex justify-between'>
        <button
          onClick={() => {
            setStable(true)
          }}
          className={`${stable && 'bg-themeOrangeLight/50 text-white font-medium'} w-1/2 rounded-[10px] transition-all h-full uppercase`}
        >
          STABLE
        </button>
        <button
          onClick={() => {
            setStable(false)
          }}
          className={`${!stable && 'bg-themeOrangeLight/50 text-white font-medium'} w-1/2 rounded-[10px] transition-all h-full uppercase`}
        >
          Volatile
        </button>
      </div>
      <div className='mt-5 md:mt-[14px]'>
        <div className={`flex flex-col w-full items-center justify-center `}>
          <div className='fromto'>
            <TokenInput
              asset={firstAsset}
              setAsset={setFirstAsset}
              otherAsset={secondAsset}
              setOtherAsset={setSecondAsset}
              amount={firstAmount}
              onInputChange={(e) => onFirstChange(e)}
              isDollar
            />
          </div>
          <div className='-my-3 z-[8] w-[40px] h-[40px] bg-black rounded-full'>
            <img src='/images/liquidity/plus.svg' />
          </div>
          <div className='fromto'>
            <TokenInput
              asset={secondAsset}
              setAsset={setSecondAsset}
              otherAsset={firstAsset}
              setOtherAsset={setFirstAsset}
              amount={secondAmount}
              onInputChange={(e) => onSecondChange(e)}
              isDollar
            />
          </div>
        </div>
      </div>

      {tradingWithImported && (
        <div className='w-full flex flex-row justify-end my-4'>
          <img alt='' src='/images/svgs/warning.svg' />
          <p className='text-red-500 text-sm mx-2'>WARNING! Token imported; trade at your own risk.</p>
        </div>
      )}

      {pair ? (
        <LiquidityDetails pair={pair} slippage={slippage} />
      ) : (
        firstAsset &&
        secondAsset && (
          <div className='mt-4'>
            <div className='text-white text-sm md:text-base font-medium pb-1 border-b border-[#757384]'>Starting Liquidity Info</div>
            <div className='flex justify-around mt-4 w-full'>
              <div className='flex flex-col items-center justify-between'>
                <p className='text-white text-sm md:text-base leading-5 font-medium'>
                  {firstAmount && secondAmount && !new BigNumber(secondAmount).isZero() ? formatAmount(firstAmount / secondAmount) : '0'}
                </p>
                <p className='text-white text-sm md:text-base leading-5'>
                  {firstAsset.symbol} per {secondAsset.symbol}
                </p>
              </div>
              <div className='flex flex-col items-center justify-between'>
                <p className='text-white text-sm md:text-base leading-5 font-medium'>
                  {firstAmount && secondAmount && !new BigNumber(firstAmount).isZero() ? formatAmount(secondAmount / firstAmount) : '0'}
                </p>
                <p className='text-white text-sm md:text-base leading-5'>
                  {secondAsset.symbol} per {firstAsset.symbol}
                </p>
              </div>
            </div>
          </div>
        )
      )}
      <div className='flex flex-col space-y-3 mt-8'>
        {pair && pair.gauge.address !== ZERO_ADDRESS && (
          <TransactButton
            onClickHandler={onAddAndStakeLiquidity}
            content={'ADD LIQUIDITY & STAKE LP'}
            className='w-full py-[13px] md:py-[14px] px-[19px]text-base md:text-lg'
          />
        )}
        <TransactButton
          onClickHandler={onAddLqiduity}
          content={'ADD LIQUIDITY'}
          className='w-full py-[13px] md:py-[14px] px-[19px] mb-3 text-base md:text-lg'
        />{' '}
        {lowTvl && <WarningTag text={'This pool has low TVL. Adding liquidity could result in loss of funds. Proceed with caution.'} />}
        {wrongAssets && (
          <WarningTag text={'Make sure your assets correspond to the pool type (i.e. USDT/USDC is a stable pool, ETH/USDC is a volatile pool).'} />
        )}
      </div>
    </>
  )
}

export default AddLiquidity

import React from 'react'
import { formatAmount } from '../../../utils/formatNumber'
import Spinner from '../../common/Spinner'

const MobilePoolInfo = ({ item }) => {
  return item ? (
    <div className='flex justify-around p-4 mt-4 bg-white/5 rounded-xl'>
      <div className='flex flex-col items-center justify-start border-r border-slate-600 pr-3'>
        <p className='text-xs font-semibold'>My Stake</p>
        <p className='text-sm'>{'$' + formatAmount(item.account.totalUsd || 0, true)}</p>
        <div className='flex flex-col items-center text-dimGray text-xs'>
          <p>
            {formatAmount(item.account.total0)} {item.token0.symbol}
          </p>
          <p>
            {formatAmount(item.account.total1)} {item.token1.symbol}
          </p>
        </div>
      </div>
      <div className='flex flex-col items-center justify-start'>
        <p className='text-xs font-semibold'>TVL</p>
        <p className='text-sm'>{'$' + formatAmount(item.gauge.tvl || 0, true)}</p>
        <div className='flex flex-col items-center text-dimGray text-xs'>
          <p>
            {formatAmount(item.token0.reserve)} {item.token0.symbol}
          </p>
          <p>
            {formatAmount(item.token1.reserve)} {item.token1.symbol}
          </p>
        </div>
      </div>
      <div className='flex flex-col items-center justify-start border-l border-slate-600 pl-3'>
        <p className='text-xs font-semibold'>My Earnings</p>
        <p className='text-sm'>{'$' + formatAmount(item.account.earnedUsd || 0, true)}</p>
        <div className='flex flex-col items-center text-dimGray text-xs'>
          <>
            {item.extraRewards?.length > 0 &&
              item.extraRewards
                .filter((reward) => !reward.isFinished || (reward.isFinished && !reward.amount.isZero()))
                .map((reward) => {
                  return (
                    <div key={reward.symbol}>
                      {formatAmount(reward.amount)} {reward.symbol}
                      <br />
                    </div>
                  )
                })}
            {formatAmount(item.account.gaugeEarned)} oLYNX
          </>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default MobilePoolInfo

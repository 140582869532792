import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Fraction, Percent } from '@uniswap/sdk-core'
import LinearGhostButton from '../../../../common/Buttons/linearGhostButton'
import Toggle from '../../../../common/Toggle'
import AddModal from './components/AddModal'
import RemoveModal from './components/RemoveModal'
import CollectModal from './components/CollectModal'
import SelectedRange from './components/SelectedRange'
// import { STABLE_TOKENS } from '../../../../../config/constants'
import { useToken } from '../../../../../hooks/v3/Tokens'
import { WETH_EXTENDED } from '../../../../../v3lib/entities/constants'
import usePrevious from '../../../../../hooks/usePrevious'
import { unwrappedToken } from '../../../../../v3lib/utils/utils'
import { usePool } from '../../../../../hooks/v3/usePools'
import { Position } from '../../../../../v3lib/entities'
import useIsTickAtLimit from '../../../../../hooks/v3/useIsTickAtLimit'
import { formatCurrencyAmount, formatTickPrice, getRatio } from '../../../../../v3lib/utils/formatTickPrice'
import { Bound } from '../../../../../state/mintV3/actions'
import { useCurrencyLogo } from '../../../../../hooks/v3/useCurrencyLogo'
import { useV3PositionFees } from '../../../../../hooks/v3/useV3PositionFees'
import useUSDTPrice from '../../../../../hooks/v3/useUSDTPrice'
import { useAlgebraBurn } from '../../../../../hooks/v3/useAlgebra'
import { BUSD, USDC, USDT } from '../../../../../config/constants/v3/routing'
import { BaseAssetsConetext } from '../../../../../context/BaseAssetsConetext'
import { fetchImportedToken } from '../../../../../utils/fetchImportToken'
import { useWeb3Wagmi } from '../../../../../hooks/useWeb3Context'
import { useImportTokens } from '../../../../../state/application/hooks'

export const useGetPriceOrderingFromPositionForUI = (position) => {
  // if token0 is a dollar-stable asset, set it as the quote token
  const USDCToken = useToken(USDC.address)
  const USDTToken = useToken(USDT.address)
  const BUSDToken = useToken(BUSD.address)

  if (!position) {
    return {}
  }

  const token0 = position.amount0.currency
  const token1 = position.amount1.currency
  const stables = [USDCToken, USDTToken, BUSDToken]
  if (stables.some((stable) => stable.equals(token0))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if token1 is an WETH, set it as the base token
  //TODO
  const bases = [...Object.values(WETH_EXTENDED)]
  if (bases.some((base) => base.equals(token1))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if both prices are below 1, invert
  if (position.token0PriceUpper.lessThan(1)) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // otherwise, just return the default
  return {
    priceLower: position.token0PriceLower,
    priceUpper: position.token0PriceUpper,
    quote: token1,
    base: token0,
  }
}

const AlgebraPosition = ({ position: positionDetails, id, manualLP, setManualLP }) => {
  const [addLiquidity, setIsLiquidity] = useState(false)
  const [withDraw, setIsWithDraw] = useState(false)
  const [fees, setCollectFees] = useState(false)
  // flag for receiving WETH
  const [receiveWETH, setReceiveWETH] = useState(false)
  const { token0: _token0Address, token1: _token1Address, liquidity: _liquidity, tickLower: _tickLower, tickUpper: _tickUpper } = positionDetails
  const { onAlgebraBurn } = useAlgebraBurn()
  const baseAssets = useContext(BaseAssetsConetext)
  const { account } = useWeb3Wagmi()

  const { addImportedToken } = useImportTokens()

  const firstRun = useRef(true)
  useEffect(() => {
    if (baseAssets.length === 0 || !firstRun.current) return
    async function fetchToken(address) {
      let token = address ? baseAssets.find((asset) => asset.address.toLowerCase() === address.toLowerCase()) : null
      if (!token && address) {
        token = await fetchImportedToken(address, account)
        if (token) {
          addImportedToken(token)
        }
      }
      return token
    }
    fetchToken(_token0Address)
    fetchToken(_token1Address)
    firstRun.current = false
  }, [baseAssets, _token0Address, _token1Address])

  const token0 = useToken(_token0Address)
  const token1 = useToken(_token1Address)

  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined
  const logoURI0 = useCurrencyLogo(currency0)
  const logoURI1 = useCurrencyLogo(currency1)

  // construct Position from details returned
  const [poolState, pool] = usePool(currency0 ?? undefined, currency1 ?? undefined)
  const [prevPoolState, prevPool] = usePrevious([poolState, pool]) || []
  const [, _pool] = useMemo(() => {
    if (!pool && prevPool && prevPoolState) {
      return [prevPoolState, prevPool]
    }
    return [poolState, pool]
  }, [pool, poolState, prevPool, prevPoolState])

  const position = useMemo(() => {
    if (_pool) {
      return new Position({
        pool: _pool,
        liquidity: _liquidity.toString(),
        tickLower: _tickLower,
        tickUpper: _tickUpper,
      })
    }
    return undefined
  }, [_liquidity, _pool, _tickLower, _tickUpper])

  const tickAtLimit = useIsTickAtLimit(_tickLower, _tickUpper, _pool?.tickSpacing)

  // prices
  const priceTickInfo = useGetPriceOrderingFromPositionForUI(position)
  const { priceLower, priceUpper, quote, base } = priceTickInfo
  // check if price is within range
  const outOfRange = _pool ? _pool.tickCurrent < _tickLower || _pool.tickCurrent >= _tickUpper : false
  const currencyQuote = quote && unwrappedToken(quote)
  const currencyBase = base && unwrappedToken(base)

  const ratio = useMemo(() => {
    return priceLower && _pool && priceUpper ? getRatio(position.token0PriceLower, _pool.token0Price, position.token0PriceUpper) : undefined
  }, [_pool, priceLower, priceUpper])

  // fees
  const [feeValue0, feeValue1] = useV3PositionFees(_pool ?? undefined, positionDetails?.tokenId, receiveWETH)
  const price0 = useUSDTPrice(token0 ?? undefined)
  const price1 = useUSDTPrice(token1 ?? undefined)

  const fiatValueOfFees = useMemo(() => {
    if (!price0 || !price1 || !feeValue0 || !feeValue1) return null

    // we wrap because it doesn't matter, the quote returns a USDC amount
    const feeValue0Wrapped = feeValue0?.wrapped
    const feeValue1Wrapped = feeValue1?.wrapped

    if (!feeValue0Wrapped || !feeValue1Wrapped) return null

    const amount0 = price0.quote(feeValue0Wrapped)
    const amount1 = price1.quote(feeValue1Wrapped)
    return amount0.add(amount1)
  }, [price0, price1, feeValue0, feeValue1])

  const prevFiatValueOfFees = usePrevious(fiatValueOfFees)
  const _fiatValueOfFees = useMemo(() => {
    if (!fiatValueOfFees && prevFiatValueOfFees) {
      return prevFiatValueOfFees
    }
    return fiatValueOfFees
  }, [fiatValueOfFees, prevFiatValueOfFees])

  const fiatValueOfLiquidity = useMemo(() => {
    if (!price0 || !price1 || !position) return
    const amount0 = price0.quote(position.amount0)
    const amount1 = price1.quote(position.amount1)
    return amount0.add(amount1)
  }, [price0, price1, position])

  const showCollectAsWeth = Boolean(
    (feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0)) && currency0 && currency1 && (currency0.isNative || currency1.isNative),
  )

  return (
    <>
      <div className={`${id > 0 ? 'mt-4' : ''} w-full px-5 py-5 bg-white/5 rounded-xl`}>
        <div
          onClick={() => {
            manualLP === id ? setManualLP(-1) : setManualLP(id)
          }}
          className='flex justify-between md:justify-start items-center md:space-x-3 cursor-pointer w-full'
        >
          <div className='flex items-center justify-between cursor-pointer w-auto md:w-full'>
            <div className='flex items-center justify-between w-full'>
              <div className='flex items-center space-x-2'>
                <div className='flex items-center'>
                  <img alt='' className='w-6 lg:w-[30px] relative shadow' src={logoURI0 || '/images/tokens/UKNOWN.png'} />
                  <img alt='' className='w-6 lg:w-[30px] -ml-3' src={logoURI1 || '/images/tokens/UKNOWN.png'} />
                </div>
                <div>
                  <p className='text-[13px] lg:text-[19px] f-f-fg font-semibold text-white'>
                    {currency0?.symbol}-{currency1?.symbol}
                  </p>
                </div>
              </div>
              <div className='hidden md:flex items-center space-x-2'>
                <div className='bg-white/10 py-1 pl-2 rounded-xl flex items-center space-x-1 pr-2 flex-shrink-0'>
                  <div className={`${_liquidity?.eq(0) ? 'bg-red-700' : outOfRange ? 'bg-warn' : 'bg-[#55A361]'} w-2 h-2 rounded-full`} />
                  <span className='text-xs md:text-sm fonts-medium text-white whitespace-nowrap'>
                    {_liquidity?.eq(0) ? 'Closed' : outOfRange ? 'Out of Range' : 'In Range'}
                  </span>
                </div>
                <div className='bg-themeOrange/50 px-2 py-1/2 rounded-xl flex-shrink-0'>
                  <span className='text-xs md:text-sm fonts-medium text-white whitespace-nowrap'>{`${new Percent(
                    pool?.fee || 100,
                    1_000_000,
                  ).toSignificant()}%`}</span>
                </div>
              </div>
            </div>
          </div>
          <button className={`${manualLP === id ? 'rotate-180' : 'rotate-0'} mt-2 md:mt-0 w-3 md:w-auto`}>
            <img alt='' src='/images/svgs/dropdown.svg' />
          </button>
        </div>
        {manualLP === id ? (
          <div className='w-full mt-3 md:mt-5'>
            <div className='flex md:hidden justify-start items-center space-x-4 my-3'>
              <div className='bg-white/10 py-1 pl-2 rounded-xl flex items-center space-x-1 pr-2 flex-shrink-0'>
                <div className={`${_liquidity?.eq(0) ? 'bg-red-700' : outOfRange ? 'bg-warn' : 'bg-[#55A361]'} w-2 h-2 rounded-full`} />
                <span className='text-xs md:text-sm fonts-medium text-white whitespace-nowrap'>
                  {_liquidity?.eq(0) ? 'Closed' : outOfRange ? 'Out of Range' : 'In Range'}
                </span>
              </div>
              <div className='bg-themeOrange/50 px-2 py-1/2 rounded-xl flex-shrink-0'>
                <span className='text-xs md:text-sm fonts-medium text-white whitespace-nowrap'>{`${new Percent(
                  pool?.fee || 100,
                  1_000_000,
                ).toSignificant()}%`}</span>
              </div>
            </div>
            <div className='flex items-center justify-between'>
              <span className='text-sm md:text-base leading-4 md:leading-5 font-medium text-white'>Liquidity</span>
              <span className='text-sm md:text-base leading-4 md:leading-5 font-light  text-white'>${formatCurrencyAmount(fiatValueOfLiquidity, 4)}</span>
            </div>
            <div className='mt-3.5'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center space-x-[5px]'>
                  <img alt='' src={logoURI0 || '/images/tokens/UKNOWN.png'} className={'w-6 md:w-7'} />
                  <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-medium'>{currency0.symbol}</span>
                </div>
                <div className='flex items-center space-x-2'>
                  <span className='text-[#DEDBF2] leading-5 font-light'>{formatCurrencyAmount(position?.amount0, 4)}</span>
                  <div className='py-1 px-2 text-sm md:text-[15px] leading-4 md:leading-[19px] text-white bg-themeOrange/50 rounded-md'>{`${ratio}%`}</div>
                </div>
              </div>
              <div className='flex items-center justify-between mt-2'>
                <div className='flex items-center space-x-[5px]'>
                  <img alt='' src={logoURI1 || '/images/tokens/UKNOWN.png'} className={'w-6 md:w-7'} />
                  <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-medium'>{currency1.symbol}</span>
                </div>
                <div className='flex items-center space-x-2'>
                  <span className='text-[#DEDBF2] leading-5 font-light'>{formatCurrencyAmount(position?.amount1, 4)}</span>
                  <div className='py-1 px-2 text-sm md:text-[15px] leading-4 md:leading-[19px] text-white bg-themeOrange/50 rounded-md'>{`${
                    100 - ratio
                  }%`}</div>
                </div>
              </div>
            </div>

            <div className='flex items-center space-x-3 mt-4 pb-4 md:pb-6 border-b border-[#5E6282] mb-3 md:mb-[18px]'>
              <LinearGhostButton
                onClickHanlder={() => {
                  setIsLiquidity(true)
                }}
                smallCase={true}
                title={'ADD LIQUIDITY'}
              />
              {_liquidity?.eq(0) ? (
                <LinearGhostButton
                  onClickHanlder={() => {
                    onAlgebraBurn(positionDetails?.tokenId)
                  }}
                  title={'BURN'}
                />
              ) : (
                <LinearGhostButton
                  onClickHanlder={() => {
                    setIsWithDraw(true)
                  }}
                  smallCase={true}
                  title={'WITHDRAW'}
                />
              )}
            </div>
            <div className='w-full'>
              <div className='flex items-center justify-between'>
                <span className='text-sm md:text-base leading-4 md:leading-5 font-medium text-white'>Unclaimed Fees</span>
                <span className='text-sm md:text-base leading-4 md:leading-5 font-light  text-white'>
                  $
                  {_fiatValueOfFees?.greaterThan(new Fraction(1, 100))
                    ? +_fiatValueOfFees.toFixed(2, { groupSeparator: ',' }) < 0.01
                      ? '<0.01'
                      : _fiatValueOfFees?.toFixed(2, {
                          groupSeparator: ',',
                        })
                    : '-'}
                </span>
              </div>
              <div className='mt-3.5'>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center space-x-[5px]'>
                    <img alt='' src={logoURI0 || '/images/tokens/UKNOWN.png'} className={'w-6 md:w-7'} />
                    <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-medium'>{currency0.symbol}</span>
                  </div>
                  <span className='text-[#DEDBF2] leading-5 font-light'>{feeValue0 ? formatCurrencyAmount(feeValue0, 4) : '-'}</span>
                </div>
                <div className='flex items-center justify-between mt-2'>
                  <div className='flex items-center space-x-[5px]'>
                    <img alt='' src={logoURI1 || '/images/tokens/UKNOWN.png'} className={'w-6 md:w-7'} />
                    <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-medium'>{currency1.symbol}</span>
                  </div>
                  <span className='text-[#DEDBF2] leading-5 font-light'>{feeValue1 ? formatCurrencyAmount(feeValue1, 4) : '-'}</span>
                </div>
              </div>
              {(feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0)) && (
                <LinearGhostButton
                  onClickHanlder={() => {
                    setCollectFees(true)
                  }}
                  title={'Claim'}
                  className='mt-[13px]'
                />
              )}
              {showCollectAsWeth && (
                <div className=' space-x-1.5 md:space-x-2 flex items-center mt-3.5'>
                  <Toggle checked={receiveWETH} onChange={() => setReceiveWETH(!receiveWETH)} small rounded toggleId='collect' />
                  <span className='text-sm md:text-base text-white'>Collect as WETH</span>
                </div>
              )}
              <SelectedRange pool={_pool} currency0={currency0} currency1={currency1} positionDetails={positionDetails} />
            </div>
          </div>
        ) : (
          Boolean(priceLower && priceUpper && tickAtLimit[Bound.UPPER] && tickAtLimit[Bound.LOWER]) && (
            <div className='md:flex-row flex flex-col items-center  space-y-1 md:space-y-0  md:space-x-[5px] text-sm font-light text-[#B8B6CB] mt-[15px] leading-4'>
              <p>{`Min ${formatTickPrice(priceLower, tickAtLimit, Bound.LOWER)} ${currencyQuote?.symbol} per ${currencyBase?.symbol}`}</p>
              <button className=''>
                <img alt='' className='md:block hidden' src='/images/svgs/double.svg' />
                <img alt='' className='md:hidden' src='/images/svgs/double-arrowv.svg' />
              </button>
              <p>{`Max ${formatTickPrice(priceUpper, tickAtLimit, Bound.UPPER)} ${currencyQuote?.symbol} per ${currencyBase?.symbol}`}</p>
            </div>
          )
        )}
      </div>
      {addLiquidity && <AddModal isOpen={addLiquidity} setIsOpen={setIsLiquidity} positionDetails={positionDetails} position={position} pool={_pool} />}
      {withDraw && <RemoveModal isOpen={withDraw} setIsOpen={setIsWithDraw} position={positionDetails} />}
      {fees && <CollectModal isOpen={fees} setIsOpen={setCollectFees} position={positionDetails} pool={_pool} feeValue0={feeValue0} feeValue1={feeValue1} />}
    </>
  )
}

export default AlgebraPosition

import React, { useState, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import lynx from '../../components/pages/otoken/lynx'
import veLynx from '../../components/pages/otoken/veLynx'
import bveLynx from '../../components/pages/otoken/bveLynx'
import DropDown from '../../components/common/Dropdown'
import StyledButton from '../../components/common/Buttons/styledButton'
import { BaseAssetsConetext } from '../../context/BaseAssetsConetext'
import { usePollOptionsData } from '../../hooks/usePollOptionsData'
import { useAllHarvest } from '../../hooks/useGauge'
import { FusionsContext } from '../../context/FusionsContext'

import '../swap/swap.scss'
import { TransactButton } from '../../components/common/Buttons/transactButton'
import { useWeb3Wagmi } from '../../hooks/useWeb3'
import useWalletModal from '../../hooks/useWalletModal'

const options = [
  { type: 'LYNX', Component: lynx },
  { type: 'veLYNX', Component: veLynx },
  { type: 'bveLYNX', Component: bveLynx },
  // { type: 'Transmute', Component: transmute },
  // { type: 'LYNX/USDC LP', Component: lp },
]

const dropDownOptions = [{ type: 'LYNX' }, { type: 'veLYNX' }, { type: 'bveLYNX' }]

const Index = () => {
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const [optionType, setOptionType] = useState('veLYNX')
  const baseAssets = useContext(BaseAssetsConetext)
  const optionsData = usePollOptionsData()
  const { onAllHarvest } = useAllHarvest()
  const fusions = useContext(FusionsContext)
  const navigate = useNavigate()

  const earnedPairs = useMemo(() => {
    return fusions.filter((pair) => !pair.account.earnedUsd.isZero())
  }, [fusions])

  return (
    <div className='w-full pt-[160px] pb-28 xl:pb-0 2xl:pb-[32px] px-5 xl:px-0 '>
      <div className='max-w-[1200px] mx-auto lg:flex items-end justify-between lg:space-x-[60px]'>
        <div className='w-full lg:w-[70%]'>
          <h1 className='text-[34px] md:text-[42px] font-semibold text-white'>Options</h1>
          <p className='text-[#b8b6cb] text-xs md:text-lg mb-2'>oLYNX is the Emissions Options Token for the Lynex Ecosystem:</p>
          <p className='text-[#b8b6cb] text-xs md:text-lg pl-5'>- Redeem for LYNX: Exchange oLYNX for LYNX at a discounted rate.</p>
          <p className='text-[#b8b6cb] text-xs md:text-lg pl-5'>- Redeem for veLYNX: Exchange oLYNX for veLYNX with 1:1 rate.</p>
          <p className='text-[#b8b6cb] text-xs md:text-lg pl-5'>- Redeem bveLYNX (bribe veLYNX) for veLYNX</p>
        </div>
        <div className='w-full lg:w-[30%]'>
          <div className='flex flex-col space-y-4'>
            <StyledButton
              onClickHandler={() => {
                navigate(`/liquidity`)
              }}
              content='EARN OLYNX'
              className='text-sm w-full md:max-w-[290px] mt-4 lg:mt-0 py-2 xl:p-3 px-3 xl:px-7'
            />
            {account ? (
              <TransactButton
                disabled={earnedPairs.length === 0}
                onClickHandler={() => {
                  onAllHarvest(earnedPairs)
                }}
                content={`CLAIM OLYNX (${earnedPairs.length})`}
                className='text-sm w-full md:max-w-[290px] mt-4 lg:mt-0 py-2 xl:p-3 px-3 xl:px-7'
              />
            ) : (
              <StyledButton
                onClickHandler={() => openWalletModal()}
                content={'CONNECT WALLET'}
                className='text-sm w-full md:max-w-[290px] mt-4 lg:mt-0 py-2 xl:p-3 px-3 xl:px-7'
              />
            )}
          </div>
        </div>
      </div>
      <div className='relative flex justify-end max-h-[86px] top-[50px] sm:right-[60px] max-w-[900px] mx-auto'>
        <img style={{ height: '86px' }} className='token2 z-[-1]' src='/images/claim/token2.svg' alt='Lynx logo 2' />
      </div>
      <div className='max-w-[1104px] mx-auto w-full'>
        <div className='flex max-w-[550px] m-auto mb-2'>
          <DropDown options={dropDownOptions} selected={optionType} setSelected={setOptionType} />
        </div>
        {options.map((option) => {
          if (option.type === optionType && optionsData.fetchedOptions) {
            return <option.Component key={option.type} baseAssets={baseAssets} optionsData={optionsData} />
          }
          return null
        })}
      </div>
      <div className='relative top-[20px] md:top-[-50px] sm:left-[-50px] max-w-[900px] mx-auto z-[-1]'>
        <img className='token1' src='/images/claim/token3.svg' alt='Lynx logo 3' />
      </div>
    </div>
  )
}

export default Index

import React, { useMemo, useState, useContext } from 'react'
import StyledButton from '../../components/common/Buttons/styledButton'
import TablePairs from '../../components/pages/singlesided/tablepairs'
import Toggle from '../../components/common/Toggle'
import MobileFilterModal from '../../components/common/MobileFilterModal'
import { useAllHarvest } from '../../hooks/useGauge'
import { PoolTypes } from '../../config/constants'
import { FusionsContext } from '../../context/FusionsContext'
import { BIG_ZERO, ZERO_ADDRESS, formatAmount } from '../../utils/formatNumber'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../components/common/Spinner'

const sortOptions = [
  {
    label: 'PAIR',
    value: 'pair',
    isDesc: true,
  },
  {
    label: 'APR',
    value: 'apr',
    isDesc: true,
  },
  {
    label: 'TVL',
    value: 'tvl',
    isDesc: true,
  },
  {
    label: 'My Stake',
    value: 'stake',
    isDesc: true,
  },
  {
    label: 'Earnings',
    value: 'earnings',
    isDesc: true,
  },
]

const defaultSort = { label: 'TVL', value: 'tvl', isDesc: true }

const Index = () => {
  const [mobileFilter, setMobileFilter] = useState(false)
  const [isStaked, setIsStaked] = useState(false)
  const [isInactive, setIsInactive] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState(defaultSort)
  const fusions = useContext(FusionsContext)
  const { onAllHarvest } = useAllHarvest()

  const ichiPairs = useMemo(() => {
    let aux = fusions.filter((item) => item.symbol.toLowerCase().includes('yieldiq'))
    if (isInactive && aux) {
      aux = aux.filter((item) => item.gauge.apr === 0)
    } else if (aux) {
      aux = aux.filter((item) => item.gauge.apr !== 0)
    }
    const result = aux.filter((item) => {
      const isCorrect = item.gauge.address !== ZERO_ADDRESS && item.isValid && item.promoted // Exclude non lynex pools
      return isCorrect && ((isStaked && !item.account.gaugeBalance.isZero()) || !isStaked)
    })
    if (!searchText || searchText === '') {
      return result
    }
    return (
      result &&
      result.filter((item) => {
        const withSpace = item.symbol.replace('/', ' ')
        const withComma = item.symbol.replace('/', ',')
        return (
          item.symbol.toLowerCase().includes(searchText.toLowerCase()) ||
          withSpace.toLowerCase().includes(searchText.toLowerCase()) ||
          withComma.toLowerCase().includes(searchText.toLowerCase())
        )
      })
    )
  }, [fusions, searchText, isStaked, isInactive])

  const stats = useMemo(() => {
    let myStake = BIG_ZERO
    let myUnstaked = BIG_ZERO
    let total = BIG_ZERO
    let earnings = BIG_ZERO
    let tvl = BIG_ZERO
    ichiPairs
      ?.filter((item) => item.symbol.toLowerCase().includes('yieldiq'))
      .map((pool) => {
        myStake = myStake.plus(pool.account.stakedUsd ?? 0)
        total = total.plus(pool.account.totalUsd ?? 0)
        earnings = earnings.plus(pool.account.earnedUsd ?? 0)
        tvl = tvl.plus(pool.tvl ?? 0)
      })
    myUnstaked = total.minus(myStake)
    return { myStake, myUnstaked, earnings, tvl }
  }, [ichiPairs])

  const sortedPairs = useMemo(() => {
    return ichiPairs.sort((a, b) => {
      let res
      switch (sort.value) {
        case 'pair':
          res = ('' + a.symbol).localeCompare(b.symbol) * (sort.isDesc ? -1 : 1)
          break
        case 'tvl':
          res = (a.gauge.tvl?.toNumber() - b.gauge.tvl?.toNumber()) * (sort.isDesc ? -1 : 1)
          break
        case 'stake':
          res = (a.account.stakedUsd?.toNumber() - b.account.stakedUsd?.toNumber()) * (sort.isDesc ? -1 : 1)
          break
        case 'apr':
          res = (a.gauge.apr - b.gauge.apr) * (sort.isDesc ? -1 : 1)
          break
        case 'earnings':
          res = (a.account?.gaugeEarned - b.account?.gaugeEarned) * (sort.isDesc ? -1 : 1)
          break
        case 'promoted':
          res = a.promoted && !b.promoted ? -1 : 1
          break
        default:
          break
      }
      return res
    })
    // return filteredPairs
  }, [ichiPairs, sort])

  const earnedPairs = useMemo(() => {
    return ichiPairs.filter((pair) => !pair.account.earnedUsd.isZero())
  }, [ichiPairs])

  const navigate = useNavigate()
  const handlePopup = () => {
    navigate('/liquidity/fusion')
  }

  return (
    <>
      <div className='max-w-[1200px] px-5 sm:px-16 md:px-28 mdLg:px-40 lg:px-5 xl:px-0 pt-20 pt-[160px] mx-auto'>
        <div className='lg:flex items-end justify-between'>
          <div className='max-w-[480px] w-full'>
            <h1 className='text-[34px] md:text-[42px] font-semibold text-white  f-f-fg'>Single token deposits</h1>
            <p className='text-[#b8b6cb] text-base md:text-lg leading-[22px] md:leading-6 mt-1'>
              Deposit single-sided liquidity and let ICHI handle the rest. Sit back and start earning!{' '}
              <a href='https://lynex.gitbook.io/lynex-docs/protocol-overview/automated-liquidity-management/ichi' target='_blank' rel='noreferrer'>
                <span className='!text-lg text-themeOrange'>Learn More</span>
              </a>
            </p>
          </div>
          <div className='flex flex-col lg:flex-row justify-between mt-6 lg:mt-0'>
            <div className='flex flex-col'>
              <div className='flex w-full items-center justify-between space-x-6'>
                <div className='flex flex-col items-center'>
                  <p className='text-dimGray text-[10px] lg:text-xs'>My Staked</p>
                  <p className='text-white lg:text-xl'>${formatAmount(stats.myStake, 2)}</p>
                </div>
                <div className='flex flex-col items-center'>
                  <p className='text-dimGray text-[10px] lg:text-xs'>My Unstaked</p>
                  <p className='text-white lg:text-xl'>${formatAmount(stats.myUnstaked, 2)}</p>
                </div>
                <div className='flex flex-col items-center'>
                  <p className='text-dimGray text-[10px] lg:text-xs'>My Earnings</p>
                  <p className='text-white lg:text-xl'>${formatAmount(stats.earnings, 2)}</p>
                </div>
                <div className='flex flex-col items-center'>
                  <p className='text-dimGray text-[10px] lg:text-xs'>Total TVL</p>
                  <p className='text-white lg:text-xl'>${formatAmount(stats.tvl, true, 2)}</p>
                </div>
              </div>
              <div className='flex lg:hidden flex-nowrap w-full space-x-3 mt-3'>
                <div className='lg:hidden border border-themeOrange w-full rounded-[3px] flex items-center lg:max-w-[250px] relative'>
                  <img className='pointer-events-none absolute left-3 w-6 h-6' alt='' src='/images/svgs/search.svg' />
                  <input
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder='Search Pair or Token'
                    className='bg-body placeholder-[#757384] h-[42px] w-full text-themeBlack text-sm xl:text-base pl-10 xl:pl-[41px] pr-4 py-3 md:py-[18px] rounded-[3px]'
                  />
                </div>
                {/* filter button for mobile */}
                <button
                  onClick={() => {
                    setMobileFilter(!mobileFilter)
                  }}
                  className='w-12 flex-shrink-0 h-[42px] lg:hidden'
                >
                  <img alt='' className='w-12 h-[42px]' src='/images/liquidity/filter.svg' />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='flex items-center justify-between w-full mt-[18px] lg:space-x-8 xl:space-x-[60px] relative'>
          {/* for desktop */}
          <div className='w-full hidden lg:flex items-center space-x-8'>
            <div className='border border-themeOrange w-full rounded-[3px] flex items-center lg:max-w-[250px] relative'>
              <img className='pointer-events-none absolute left-3 w-6 h-6' alt='' src='/images/svgs/search.svg' />
              <input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder='Search Pair or Token'
                className='bg-body placeholder-[#757384] h-[42px] w-full text-themeBlack text-sm xl:text-base pl-10 xl:pl-[41px] pr-4 py-3 md:py-[18px] rounded-[3px]'
              />
            </div>
            <div className='flex items-center space-x-2'>
              <Toggle checked={isStaked} onChange={() => setIsStaked(!isStaked)} toggleId='isStaked' />
              <p className='text-[#DEDBF2] text-sm xl:text-[17px] whitespace-nowrap'>Staked Only</p>
            </div>
            <div className='flex items-center space-x-2'>
              <Toggle checked={isInactive} onChange={() => setIsInactive(!isInactive)} toggleId='isInactive' />
              <p className='text-[#DEDBF2] text-sm xl:text-[17px] whitespace-nowrap'>Inactive</p>
            </div>
          </div>

          {/* mobile filters popup */}
          {mobileFilter && (
            <MobileFilterModal
              setMobileFilter={setMobileFilter}
              tabs={Object.values(PoolTypes)}
              isStaked={isStaked}
              setIsStaked={setIsStaked}
              isInactive={isInactive}
              setIsInactive={setIsInactive}
              sort={sort}
              setSort={setSort}
              sortOptions={sortOptions}
            />
          )}
          <div className='lg:mt-0 w-full lg:w-[80%] flex justify-between items-center space-x-3 '>
            <StyledButton
              disabled={earnedPairs.length === 0}
              onClickHandler={() => {
                onAllHarvest(earnedPairs)
              }}
              content={`CLAIM ALL (${earnedPairs.length})`}
              className='text-xs lg:text-sm w-full md:max-w-1/2 lg:max-w-[380px] h-[44px] px-3 xl:px-4 rounded-md'
            />
            <StyledButton
              content={'ADD LIQUIDITY'}
              className={'text-xs lg:text-sm w-full md:max-w-1/2 lg:max-w-[380px] h-[44px] px-3 xl:px-4 rounded-md'}
              onClickHandler={handlePopup}
            />
          </div>
        </div>
        {fusions.length > 0 ? (
          <TablePairs pairsData={sortedPairs} sort={sort} setSort={setSort} sortOptions={sortOptions} />
        ) : (
          <div className='flex flex-col w-full items-center p-3 mt-10'>
            <Spinner />
            <div className='text-white text-sm mt-2'>
              <p>Loading Pools..</p>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Index
